<script setup lang="ts">
import { computed } from '#imports'

const props = defineProps<{
  id?: string
  ariaLabel?: string
  error?: boolean
  loading?: boolean
  modelValue?: string
  placeholder: string
}>()

const emit = defineEmits<{
  'update:modelValue': [input: string]
  enter: [input: string]
}>()

const search = computed({
  get() {
    return props.modelValue
  },
  set(val: string) {
    emit('update:modelValue', val)
  },
})

const clear = () => {
  search.value = ''
  emit('update:modelValue', '')
}
</script>

<template>
  <div
    class="relative flex w-full flex-1 items-center justify-between rounded-full text-neutral-500"
  >
    <div class="z-2 pointer-events-none absolute">
      <div
        class="i-ph-magnifying-glass-bold h-4 w-4 translate-x-2 transform"
        :class="{
          'text-error-dark': error,
          'text-neutral-500': !error,
        }"
      />
    </div>

    <input
      :id="id"
      v-model="search"
      class="sibling focus:border-primary z-1 w-full rounded-lg border px-7 py-2 text-sm text-xs leading-4 text-neutral-900 focus:outline-transparent focus:ring"
      :class="{
        'bg-error-light border-error-dark placeholder-error-dark': error,
        'border-neutral-300 bg-transparent': !error,
      }"
      :aria-label="ariaLabel || 'Search'"
      :placeholder="placeholder"
      type="search"
      @keyup.esc="clear()"
    />
    <div
      class="pointer-events-none absolute flex h-full w-full transform flex-col justify-end overflow-hidden rounded-full bg-white"
    >
      <transition
        enter-active-class="transform"
        enter-from-class="translate-y-1"
        enter-to-class="translate-y-0"
        leave-active-class="transform"
        leave-from-class="translate-y-0"
        leave-to-class="translate-y-1"
      >
        <div
          v-if="loading"
          class="h-3px -z-1 bg-primary-light-hover w-full animate-pulse transition-transform"
        >
          <div class="bar-value bg-primary-light h-full w-full rounded" />
        </div>
      </transition>
    </div>
    <button
      v-if="search"
      class="z-2 btn-icon outline-primary-300 absolute right-2 -translate-x-0 transform hover:text-neutral-700 focus:text-neutral-700"
      :class="search ? [] : ['pointer-events-none']"
      :disabled="!search"
      type="button"
      @click="clear()"
    >
      <div v-if="search" class="i-ph-x w-4" />
    </button>
  </div>
</template>

<style scoped>
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
  opacity: 0;
  pointer-events: none;
}

input::placeholder {
  @apply text-xs;
}

.bar-value {
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  24% {
    transform: translateX(0) scaleX(0.24);
  }
  62% {
    transform: translateX(38%) scaleX(0.62);
  }
  100% {
    transform: translateX(100%) scaleX(0.62);
  }
}
</style>
